import { graphql, useStaticQuery } from "gatsby"

export default function AppDeveloperData() {
  return useStaticQuery(graphql`
    query queryAppDeveloper {
      seo: sanitySeo(slug: { current: { eq: "app-developer" } }) {
        meta_title
        meta_description
        keywords
        meta_image {
          asset {
            url
          }
        }
        alt_tag
      }
      hero: sanityHero(slug: { current: { eq: "leading-australian" } }) {
        title
        hints {
          parts
        }
        svgUpload
        isHintsAnimation
        backgroundColor {
          hex
        }
      }
      video: sanityVideoSection(slug: { current: { eq: "who-we-are" } }) {
        description
        slug {
          current
        }
        quote
        title
        hints {
          parts
        }
        backgroundColor {
          hex
        }
        video_link
        isHintsAnimation
      }
      features: sanityFeatures(
        slug: { current: { eq: "benefits-of-working" } }
      ) {
        id
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      action: sanityCallAction(
        slug: { current: { eq: "can-you-imagine-the-possibilities" } }
      ) {
        title
        button
        description
        image {
          asset {
            url
          }
        }
        background {
          asset {
            url
          }
        }
        backgroundColor {
          hex
        }
        target
      }
      testimonial: sanityTestimonials(slug: { current: { eq: "dark" } }) {
        title
        testimonials {
          author {
            name
            position
            image {
              asset {
                url
              }
            }
          }
          text
        }
        shadowBackgroundColor {
          hex
        }
        cardBackgroundColor {
          hex
        }
        backgroundColor {
          hex
        }
      }
      services: sanityFeatures(
        slug: { current: { eq: "what's-different-about" } }
      ) {
        id
        hints {
          parts
        }
        slide
        title
        features {
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      features2: sanityFeatures(
        slug: { current: { eq: "our-app-development-services" } }
      ) {
        features {
          backgroundColor {
            hex
          }
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        id
        hints {
          parts
        }
        title
        slide
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      process: sanityCallAction(
        slug: { current: { eq: "do-you-have-an-idea" } }
      ) {
        title
        button
        image {
          asset {
            url
          }
        }
        description
        background {
          asset {
            url
          }
        }
        backgroundColor {
          hex
        }
        target
      }
      formula: sanityFeatures(
        slug: { current: { eq: "app-developer-our-formula-for-success" } }
      ) {
        id
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          backgroundColor {
            hex
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      blog: allSanityBlog(limit: 3, sort: { fields: createDate, order: DESC }) {
        nodes {
          title
          summary
          image {
            image {
              asset {
                url
              }
            }
            alt
          }
          createDate
          slug {
            current
          }
        }
      }
      slides: sanitySlides(slug: { current: { eq: "development-problems" } }) {
        slug {
          _key
          _type
          current
        }
        isHintsAnimation
        slides {
          title
          solution
          problem
          button
          action
          link
        }
        hints {
          parts
        }
        backgroundColor {
          hex
        }
        title
      }

      faq: sanityFaq(slug: { current: { eq: "app-development-faq" } }) {
        title
        backgroundColor {
          hex
        }
        questions {
          response
          faq
          id
        }
      }
    }
  `)
}
